import Link from 'next/link'
import { FC } from 'react'

import { MobileNoticeable } from './Noticeable'
import { useNavbar } from './useNavbar'

type MobileNavProps = {
  closeMenu: () => void
}

export const MobileNav: FC<MobileNavProps> = ({ closeMenu }) => {
  const { links } = useNavbar()

  return (
    <div className="absolute inset-x-0 top-0 z-10 origin-top-right p-2 transition md:hidden">
      <div className="ring-opacity/5 overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black">
        <div className="flex items-center justify-between px-5 pt-4">
          <div>
            <img
              className="h-8 w-auto rounded-md"
              src="https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/black-logo.png"
              alt="Logo"
            />
          </div>
          <div className="-mr-2">
            <button
              type="button"
              onClick={closeMenu}
              className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Close main menu</span>
              <svg
                className="size-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="main-menu"
        >
          <div className="space-y-1 px-2 pb-3 pt-2" role="none">
            {links.map((link) => {
              return (
                <Link
                  href={link.href}
                  key={link.label}
                  className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                  role="menuitem"
                >
                  {link.label}
                </Link>
              )
            })}
            <MobileNoticeable />
          </div>
          <div role="none">
            <Link
              href="/schedule-demo"
              className="block w-full bg-gray-50 px-5 py-3 text-center font-medium text-blue-600 hover:bg-gray-100 hover:text-blue-700"
              role="menuitem"
            >
              Schedule demo
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
