import Link from 'next/link'
import { useState } from 'react'

import { MobileNav } from './MobileNav'
import { Noticeable } from './Noticeable'
import { useNavbar } from './useNavbar'

export const Navbar2 = () => {
  const { links } = useNavbar()
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  return (
    <div className="bg-white">
      <header>
        <div className="relative bg-white">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link href="/">
                <span className="sr-only">driverseat</span>
                <img
                  className="h-8 w-auto rounded-md sm:h-10"
                  src="https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/black-logo.png"
                  alt="Home"
                />
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <button
                type="button"
                onClick={() => setShowMobileMenu(true)}
                className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="size-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden space-x-10 md:flex">
              {links.map((link) => {
                return (
                  <Link
                    href={link.href}
                    key={link.href}
                    className="text-base font-medium text-gray-500 hover:text-gray-900"
                  >
                    {link.label}
                  </Link>
                )
              })}
              <Noticeable />
            </nav>
            <div className="hidden h-10 items-center justify-end md:flex md:flex-1 lg:w-0">
              <Link
                href="/schedule-demo"
                className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700"
              >
                Schedule demo
              </Link>
            </div>
          </div>

          {showMobileMenu && (
            <MobileNav closeMenu={() => setShowMobileMenu(false)} />
          )}
        </div>
      </header>
    </div>
  )
}
