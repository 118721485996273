import Link from 'next/link'

export const CTA = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-blue-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:p-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">
                  Ready to improve your customer experience?
                </span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-blue-200">
                Schedule a no commitment live demo of the Driverseat platform.
              </p>
              <Link
                href="/schedule-demo"
                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-6 py-3 text-base font-medium text-blue-600 shadow hover:bg-blue-50"
              >
                Schedule demo
              </Link>
            </div>
          </div>
          <div className="aspect-h-3 aspect-w-5 -mt-6 md:aspect-h-1 md:aspect-w-2">
            <img
              className="translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-24"
              src="https://res.cloudinary.com/driverseat/image/upload/f_auto,fl_progressive:semi,c_limit,w_1280/screely-1614808911991_fembba.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
