import { useEffect, useState } from 'react'

const widgetId = 'gAOtGrpklZyVaUgUesPw'

export const Noticeable = () => {
  const [updates, setUpdates] = useState(0)

  useEffect(() => {
    // @ts-ignore
    window.noticeable.on(
      'widget:publication:unread_count:changed',
      widgetId,
      (e: { detail: { value: number } }) => {
        setUpdates(e.detail.value)
      },
    )

    // @ts-ignore
    window.noticeable.render('widget', widgetId)
  }, [])

  const openModal = () => {
    // @ts-ignore
    window.noticeable.do('widget:open', widgetId)
  }

  return (
    <button
      id="noticeable-widget"
      onClick={openModal}
      className="relative flex items-center space-x-2 text-base font-medium text-gray-500 hover:text-gray-900"
    >
      <span>What's New</span>

      {updates > 0 && (
        <span
          className="flex size-5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
          id="counter"
        >
          {updates}
        </span>
      )}
    </button>
  )
}

export const MobileNoticeable = () => {
  const [updates, setUpdates] = useState(0)

  useEffect(() => {
    // @ts-ignore
    window.noticeable.on(
      'widget:publication:unread_count:changed',
      widgetId,
      (e: { detail: { value: number } }) => {
        setUpdates(e.detail.value)
      },
    )

    // @ts-ignore
    window.noticeable.render('widget', widgetId)
  }, [])

  const openModal = () => {
    // @ts-ignore
    window.noticeable.do('widget:open', widgetId)
  }

  return (
    <button
      id="noticeable-widget"
      onClick={openModal}
      className="flex w-full items-center space-x-2 rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
      role="menuitem"
    >
      <span>What's New</span>

      {updates > 0 && (
        <span
          className="flex size-5 items-center justify-center rounded-full bg-red-500 text-xs font-bold text-white"
          id="counter"
        >
          {updates}
        </span>
      )}
    </button>
  )
}
