export const useNavbar = () => {
  return {
    links: [
      {
        label: 'Explore Features',
        href: '/features',
      },
      // {
      //   label: 'Pricing',
      //   href: '/pricing',
      // },
      {
        label: 'Blog',
        href: '/blog',
      },
    ],
  }
}
