import Link from 'next/link'

import { useNavbar } from './useNavbar'

export const Footer = () => {
  const { links } = useNavbar()

  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl overflow-hidden px-4 py-12 sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {links.map((link) => {
            return (
              <div
                key={link.label}
                className="px-5 py-2 text-base text-gray-500 hover:text-gray-900"
              >
                <Link href={link.href}>{link.label}</Link>
              </div>
            )
          })}

          <div className="px-5 py-2">
            <Link
              href="/privacy-policy"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Privacy Policy
            </Link>
          </div>

          <div className="px-5 py-2">
            <Link
              href="/cookies"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Cookies
            </Link>
          </div>

          <div className="px-5 py-2">
            <Link
              href="/terms-of-service"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Terms of Service
            </Link>
          </div>
          <div className="px-5 py-2">
            <a
              href="https://status.driverseat.io"
              target="_blank"
              rel="noreferrer"
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Status
            </a>
          </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} Driverseat. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
