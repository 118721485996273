import Head from 'next/head'
import { NextSeo } from 'next-seo'
import { FC, PropsWithChildren } from 'react'

import { CTA } from './CTA'
import { Footer } from './Footer'

type LayoutProps = {
  title: string
  description: string
  showCTA?: boolean
  image?: string
}

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  title,
  description,
  children,
  showCTA = true,
  image = 'https://res.cloudinary.com/driverseat/image/upload/v1648240117/driverseat/OG_1.png',
}) => {
  return (
    <div>
      <NextSeo
        title={title}
        description={description}
        noindex={
          process.env.NEXT_PUBLIC_API_ENDPOINT !==
          'https://api.driverseat.io/graphql'
        }
        openGraph={{
          title,
          description,
          images: [
            {
              url: image,
              alt: 'driverseat banner',
            },
          ],
        }}
      />
      <Head>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          sizes="192x192"
          type="image/png"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          sizes="96x96"
          type="image/png"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {children}
      {showCTA && <CTA />}

      <Footer />
    </div>
  )
}
